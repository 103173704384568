// import Head from 'next/head'
import Link from 'next/link'
// import Image from 'next/image'
import styles from '../styles/Home.module.css'

export default function Home(): JSX.Element {
	return (
		<div className={styles.container}>
			<main className={styles.main}>
				<h1 className={styles.title}>ナガタク管理画面へようこそ！</h1>

				{/* <p className={styles.description}>
					全権限を持った管理者ユーザーとしてログインしています。アカウントの管理には十分注意してください。
				</p> */}

				<div className={styles.grid}>
					<Link href="/orders">
						<a className={styles.card}>
							<h2>注文受注 &rarr;</h2>
							<p>店舗営業中に注文を受注して業務管理を行います。</p>
						</a>
					</Link>

					<Link href="/settings/menu">
						<a className={styles.card}>
							<h2>店舗管理 &rarr;</h2>
							<p>商品の新規作成・編集、営業時間の設定などの店舗管理を行います。</p>
						</a>
					</Link>
				</div>
			</main>
		</div>
	)
}
